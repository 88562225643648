const InitFilterResultsForBuilder = ($filterTagContainer) => {
  const formSelector = $filterTagContainer.data('form-selector');
  const $filterForm = $(`.${formSelector}`);
  const $submitBtn = $('button[type="submit"]');
  const $tagsContainer = $filterTagContainer.find('.JS-filter-tags');

  const toggleView = () => {
    const $tags = $tagsContainer.find('.tag');

    if ($tags.length) {
      $filterTagContainer.removeClass('d-none').addClass('d-flex');
    } else {
      $filterTagContainer.removeClass('d-flex').addClass('d-none');
    }
  };

  const INPUT_TYPES = {
    checkbox: 'checkbox',
    select: 'select-one',
    text: 'text',
  };

  const buildTagElement = ({ name, value }) => `
      <div class="tag dark mb-2 JS-filter-tag" data-filter-name="${name}">
        ${value}
        <i class="fas fa-times ml-2"></i>
      </div>
    `;

  const buildTags = () => {
    const values = $filterForm.serializeArray();

    $tagsContainer.empty();

    values.forEach((value) => {
      const $filterInput = $filterForm.find(`[name*="${value.name}"]`);
      const $label = $filterInput.siblings().filter('label');
      const inputType = $filterInput.prop('type');

      let component = null;

      if (value.value !== '') {
        switch (inputType) {
          case INPUT_TYPES.select:
            component = buildTagElement({
              name: value.name,
              value: `${$label.text()}: ${$filterInput.find('option:selected').text()}`,
            });
            break;
          case INPUT_TYPES.checkbox:
            component = buildTagElement({
              name: value.name,
              value: `${$filterInput.parent().siblings('.toggle-switch-label').text()}`,
            });
            break;
          default:
            component = buildTagElement({
              name: value.name,
              value: `${$label.text()}: ${value.value}`,
            });
            break;
        }
      }

      if (component) $tagsContainer.append(component);
    });

    toggleView();
  };

  const onRemoveFilter = ($tag) => {
    if (!$tag.length) return;

    const filterName = $tag.data('filter-name');
    const $filterInput = $filterForm.find(`[name*="${filterName}"]`);

    if (!$filterInput.length) return;

    const inputType = $filterInput.prop('type');

    switch (inputType) {
      case INPUT_TYPES.select:
        $filterInput.val('').trigger('change');
        break;
      case INPUT_TYPES.checkbox:
        $filterInput.prop('checked', false);
        break;
      default:
        $filterInput.val('');
        break;
    }

    $tagsContainer.empty();

    buildTags();
    toggleView();

    $filterForm.submit();
  };

  const onResetFilters = () => {
    $tagsContainer.empty();
    toggleView();
  };

  $submitBtn.on('click', buildTags);

  $filterTagContainer.on('click', '.JS-filter-tag svg', e => onRemoveFilter($(e.target).closest('.tag')));
  $filterForm.on('click', 'button[type="reset"]', onResetFilters);

  toggleView();
};

export default InitFilterResultsForBuilder;
