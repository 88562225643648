import InitFilterResultsForBuilder from './build_filter_resuls_for';

const InitFilterResultsFor = ($filterTagContainer) => {
  const $tags = $filterTagContainer.find('.JS-filter-tag');

  const toggleView = (canShow = true) => {
    if (canShow) {
      $filterTagContainer.removeClass('d-none').addClass('d-flex');
    } else {
      $filterTagContainer.removeClass('d-flex').addClass('d-none');
    }
  };

  if (!$tags.length) {
    toggleView(false);
    return;
  }

  toggleView(true);

  const formSelector = $filterTagContainer.data('form-selector');
  const $filterForm = $(`.${formSelector}`);

  const onRemoveFilter = ($tag) => {
    if (!$tag.length) return;

    const filterName = $tag.data('filter-name');
    const $filterInput = $filterForm.find(`[name*="${filterName}"]`);

    if (!$filterInput.length) return;

    $filterInput.val('');
    $filterForm.submit();
  };

  $tags.on('click', 'svg', e => onRemoveFilter($(e.target).closest('.tag')));
};

onLoad(($) => {
  const $filterTagContainer = $('.JS-filter-results-for');

  if (!$filterTagContainer.length) return;

  $filterTagContainer.each((_, container) => {
    const $container = $(container);

    const buildWithJavascript = $container.data('build-with-javascript');

    if (buildWithJavascript) {
      InitFilterResultsForBuilder($container);
    } else {
      InitFilterResultsFor($container);
    }
  });
});
