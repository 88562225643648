import { listIpBankAccounts, toggleBtnLoadIndicator } from '../async_actions';
import setOwnerOptions from '../helpers/ip_bank_accounts_filter_helper';

const initAddAccountsIpBankAccountsTable = ($) => {
  const filterContainerSelector = '.JS-ip-bank-accounts-filters-add-accounts';
  const filterParams = {};
  const tableContainerSelector = '.JS-add-accounts-table';

  // #region Private Functions
  const toggleLoadIndicatorButton = (isLoading) => {
    const $submitFilterButton = $("button[name='add_account_filter']");

    toggleBtnLoadIndicator($submitFilterButton, 'addAccountsSubmitFilter', isLoading);
  };

  const buildFilterOptions = (ipBankAccounts) => {
    const $filterForm = $('.JS-ip-bank-accounts-filters-add-accounts');

    setOwnerOptions($filterForm.find('#name'), ipBankAccounts);
  };
  // #endregion Private Functions

  // #region Async Functions
  const onListIpBankAccounts = ({
    onSuccess = () => { },
    onError = () => { },
  }) => {
    filterParams.user_id = $('#user_id').val();

    listIpBankAccounts({
      $,
      params: filterParams,
      onSuccess,
      onError,
    });

    filterParams.user_id = null;
  };
  // #endregion Async Functions

  // #region Init Functions
  const initFilterParams = () => {
    filterParams.q = {
      account_number_string_cont: null,
      client_document_number_cont: null,
      client_id_eq: null,
      id_not_in: null,
    };
    filterParams.client_id = null;
    filterParams.kind_cd = null;
    filterParams.user_id = null;
  };
  // #endregion Init Functions

  // #region Init Table
  const renderHasAccessTag = row => `
    <div class="has-access-tooltip has-access-tooltip-${row.ip_bank_account_id}">
      <div class="tag success">Já possui acesso</div>
    <div>
  `;

  const initTable = () => {
    const $tableContainer = $(tableContainerSelector);

    const tableOptions = {
      columns: ['Titular da Conta', '', 'Agência', 'Conta', 'CNPJ', 'Tipo'],
      startPage: 1,
      idKey: 'ip_bank_account_id',
      itemsPerPageOptions: [10, 50, 100, 300, 500],
      modelKeys: [
        { key: 'ip_bank_account_id' },
        { key: 'name' },
        {
          key: 'current_user_has_access',
          render: row => (row.current_user_has_access ? renderHasAccessTag(row) : ''),
        },
        { key: 'agency' },
        { key: 'account_number' },
        { key: 'document_number' },
        { key: 'kind', render: row => row.kind.translated },
      ],
      rows: [],
      selectorPrefix: '.JS-user-add-account',
    };

    const $ipBankAccountsTable = $tableContainer.paginatedTable(tableOptions);

    initFilterParams();

    const initPopover = () => {
      const $tooltips = $tableContainer.find('.has-access-tooltip');

      if (!$tooltips.length) return;

      $tooltips.popover({
        trigger: 'hover',
        placement: 'top',
        toggle: 'popover',
        container: $tooltips.length > 1 ? 'body' : '.has-access-tooltip',
        customClass: 'has-access-tooltip',
        content: `Este usuário já possui acesso à esta conta.
                  Ao selecioná-la será possível realizar uma combinação de perfis e permissões.`,
      });
    };

    const onSuccessFn = (ipBankAccounts = []) => {
      $ipBankAccountsTable.updateRows(ipBankAccounts);
      buildFilterOptions(ipBankAccounts);
      initPopover();
    };

    $ipBankAccountsTable.setPageChangeCallback(initPopover);

    onListIpBankAccounts({ onSuccess: onSuccessFn });

    return $ipBankAccountsTable;
  };
  // #endregion Init Table

  // #region Init Filter
  const initFilter = ($ipBankAccountsTable) => {
    const $filterForm = $(filterContainerSelector);

    if (!$filterForm.length) return;

    // #region Validation
    const validateInput = ($input) => {
      const isValid = $input.val();

      if (isValid) {
        $input.addClass('is-valid');
      } else {
        $input.removeClass('is-valid');
      }
    };

    const validateAllInputs = () => {
      const $inputs = $filterForm.find(':input');

      $inputs.each((_, input) => {
        const $input = $(input);

        validateInput($input);
      });
    };
    // #endregion Validation

    const setFilterParams = () => {
      filterParams.q = {
        account_number_string_cont: $filterForm.find('#account').val(),
        client_document_number_cont: $filterForm.find('#document_number').val(),
        client_id_eq: $filterForm.find('#name').val(),
        id_not_in: $filterForm.find('#id_not_in').prop('checked'),
      };
      filterParams.kind_cd = $filterForm.find('#kind').val();
      filterParams.client_id = $('#client_id').val();
      filterParams.user_id = $('#user_id').val();
    };

    const disableForm = (canDisable) => {
      toggleLoadIndicatorButton(canDisable);
      $filterForm.find(':input').prop('disabled', canDisable);
    };

    const onListSuccessfully = (ipBankAccounts = []) => {
      $ipBankAccountsTable.updateRows(ipBankAccounts);

      disableForm(false);
    };

    const onSubmit = (e) => {
      e.preventDefault();

      validateAllInputs();
      setFilterParams();
      disableForm(true);

      const onSuccessFn = response => onListSuccessfully(response);
      const onErrorFn = () => disableForm(false);

      onListIpBankAccounts({ onSuccess: onSuccessFn, onError: onErrorFn });
    };

    // #region Event Handler Functions
    const onReset = () => {
      const $inputs = $filterForm.find(':input');

      $inputs.removeClass('is-valid');

      disableForm(true);
      initFilterParams();

      const onSuccessFn = response => onListSuccessfully(response);
      const onErrorFn = () => disableForm(false);

      onListIpBankAccounts({ onSuccess: onSuccessFn, onError: onErrorFn });
    };
    // #endregion Event Handler Functions

    // #region Init Event Handler
    const initEventHandlers = () => {
      $filterForm.on('submit', onSubmit);
      $filterForm.on('reset', onReset);
    };
    // #endregion Init Event Handler

    // #region Init
    const init = () => {
      initEventHandlers();
    };
    // #endregion Init

    init();
  };
  // #endregion Init Filter

  const $table = initTable();

  buildFilterOptions();
  initFilter($table);

  return $table;
};

export default initAddAccountsIpBankAccountsTable;
