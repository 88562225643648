import { CONTEXT, PAGES } from '../helpers/navigation_helper';
import initAddAccountForm from './add_account_form';
import initUserRoleTable from '../tables/invitation_role_table';
import onChangeVisibility from '../helpers/visibility_helper';
import validateFields from '../helpers/validation_helper';

const initInvitationPage = ({
  $,
  context = CONTEXT.invite,
  ipBankAccounts = null,
  ipBankAccountsTable,
  navigator,
  selectOptions,
}) => {
  const formContainerSelector = '.JS-invitation';

  const $inviteFormPage = $(formContainerSelector);

  if (!$inviteFormPage.length) return undefined;

  const $ipBankAccountsTable = ipBankAccountsTable;
  const tableRolesContainerSelector = '.JS-invitation-roles-table';

  let $invitationRoleTable = null;

  // #region Role Validation
  const validateDuplicatedRole = ($input) => {
    const selection = $invitationRoleTable.getRoles();
    const accountId = $input.data('account-id');
    const clientId = $input.data('client-id');
    const roleId = $input.val();
    const filteredSelection = selection.filter((role) => {
      const { ipBankAccountId, originalAccountId } = role;

      return ipBankAccountId !== clientId && accountId === originalAccountId;
    });

    let isDuplicated = false;

    filteredSelection.forEach((role) => {
      const $select = $(`#role-${role.ipBankAccountId}`);
      const value = $select.val() || role.roleId;

      if (roleId !== '' && value === roleId) {
        isDuplicated = true;
      }
    });

    return isDuplicated;
  };

  const getValidationOptions = () => {
    const $inputs = $inviteFormPage.find(`${tableRolesContainerSelector} select`);

    const getHintTagElement = $input => $input.siblings('.invalid-feedback');

    return {
      $inputs,
      customValidation: ($input) => {
        const inputValue = $input.val();
        const isDuplicated = validateDuplicatedRole($input);

        let isValid = true;

        if (inputValue) {
          isValid = !!inputValue;
        } else {
          isValid = false;
        }
        const hint = isDuplicated ? 'Este perfil já está vinculado a este usuário.' : '';
        return { isValid: isValid && !isDuplicated, hint };
      },
      forceHideError: true,
      showSucess: false,
      hintTagSelector: getHintTagElement,
    };
  };

  const validateRoles = () => {
    const options = getValidationOptions();

    validateFields(options);
  };

  const areRolesDuplicated = () => {
    const selection = $invitationRoleTable.getRoles();

    let areDuplicatedRoles = false;

    selection.forEach((role) => {
      const filteredSelection = selection.filter(selectedRole => role.ipBankAccountId !== selectedRole.ipBankAccountId);

      filteredSelection.forEach((filteredRole) => {
        const accountId = filteredRole.originalAccountId;

        if (
          (accountId === role.originalAccountId)
          && (role.roleId === filteredRole.roleId)
        ) {
          areDuplicatedRoles = true;
        }
      });
    });

    return areDuplicatedRoles;
  };
  // #endregion Role Validation

  // #region Private Functions
  const getSelectedIpBankAccounts = () => ipBankAccounts || $ipBankAccountsTable.getSelection();

  const resetRoleTable = () => {
    $invitationRoleTable.setSelection([]);
    $invitationRoleTable.goToPage(1);
    $invitationRoleTable.fillSelectInputs();
    $invitationRoleTable.cancelBatchAction();
  };

  const copyObject = (object) => {
    const newObject = {};
    const keys = Object.keys(object);

    keys.forEach((key) => {
      newObject[key] = object[key];
    });

    return newObject;
  };

  const addTimestampToIds = (rows = []) => {
    const tableRows = [];
    const timestamp = new Date().getTime();

    rows.forEach((row, index) => {
      const copiedObject = copyObject(row);

      const id = copiedObject.id;
      const ipBankAccountId = copiedObject.ip_bank_account_id;
      const idWithTimestamp = `${ipBankAccountId}_${timestamp}_${index}`;

      copiedObject.ipBankAccountId = idWithTimestamp;

      if (id) copiedObject.id = id;

      tableRows.push(copiedObject);
    });

    return { rows: tableRows, timestamp };
  };

  const setNewRolesOnTable = (selectedRoles = []) => {
    if (!$invitationRoleTable) return;

    $invitationRoleTable.setRoles(selectedRoles);
  };

  const fillSelectedRoleList = ({ roles = [], timestamp }) => {
    const selectedRoles = [];

    roles.forEach((role, index) => {
      const id = role.id;
      const ipBankAccountId = role.ip_bank_account_id;
      const idWithTimestamp = `${ipBankAccountId}_${timestamp}_${index}`;

      const $select = $(`#role-${idWithTimestamp}`);

      const originalId = $select.data('original-id');

      if (originalId === role.id) $select.val(role.kind_cd);

      selectedRoles.push({
        ipBankAccountId: idWithTimestamp,
        originalAccountId: role.ip_bank_account_id,
        id,
        roleId: role.kind_cd,
      });
    });

    setNewRolesOnTable(selectedRoles);
  };

  const updateUserName = () => {
    const userName = $('.JS-pre-invitation :input[name="name"]').val();

    $inviteFormPage.find('.guest-name').html(userName);
  };

  const getCurrentFilledRoles = () => {
    const userRoles = $('#user_roles').val();

    if (!userRoles) return null;

    const parsedUserRoles = JSON.parse(userRoles);

    if (!parsedUserRoles.length) return null;

    return parsedUserRoles;
  };

  const setRoleTableRows = (timestamp) => {
    const roles = context === CONTEXT.edit
      ? ipBankAccounts
      : getCurrentFilledRoles();

    if (!roles || !roles.length) return;

    fillSelectedRoleList({ roles, timestamp });
  };

  const addNewAccountsToTable = (rows = []) => {
    if (!$invitationRoleTable) return;

    $invitationRoleTable.addNewAccounts(rows);
  };

  const getInvitationTableRoles = () => {
    if (!$invitationRoleTable) return [];

    return $invitationRoleTable.getRoles();
  };

  const getInvitationTableSelectedAccountsCount = () => {
    if (!$invitationRoleTable) return null;

    return $invitationRoleTable.selectedAccountsCount();
  };

  const setOnlyUniqueIdsForIpBankAccountsTableSelection = () => {
    const selection = $ipBankAccountsTable.getSelection();
    const mappedSelection = selection.map(s => s.ip_bank_account_id);
    const uniqueIdArray = Array.from(new Set(mappedSelection));

    $ipBankAccountsTable.setSelection(uniqueIdArray);
  };

  const onGetRolesValue = (showErrorMessage = true) => {
    if (!$invitationRoleTable) return [];

    // eslint-disable-next-line no-underscore-dangle
    const rowsFilter = selected => !!selected.roleId && !selected._destroy;

    const roles = getInvitationTableRoles();
    const filteredRoles = roles.filter(rowsFilter);
    const rolesSelected = getInvitationTableSelectedAccountsCount();
    const isSameLength = filteredRoles.length === rolesSelected;
    const hasDuplicatedRoles = areRolesDuplicated();

    if (showErrorMessage) validateRoles();

    return { roles, isValid: isSameLength && !hasDuplicatedRoles };
  };

  const showContainer = ($container) => {
    $container.removeClass('d-none').addClass('d-block');
  };

  const hideContainer = ($container) => {
    $container.removeClass('d-block').addClass('d-none');
  };

  const verifyRolesValidation = () => {
    const { isValid } = onGetRolesValue(false);
    const $disabledButton = $inviteFormPage.find('.JS-save-user-disabled');
    const $button = $inviteFormPage.find('.JS-save-user');

    if (isValid) {
      showContainer($button);
      hideContainer($disabledButton);
    } else {
      showContainer($disabledButton);
      hideContainer($button);
    }
  };
  // #endregion Private Functions

  // #region Init Roles Table
  const buildInvitationTable = ({
    currentSelection,
    selectOptions: options,
  }) => initUserRoleTable({
    $,
    context,
    currentSelection,
    selectOptions: options,
    onChangeRoleBatch: () => {
      validateRoles();
      verifyRolesValidation();
    },
    onChangeRole: () => {
      validateRoles();
      verifyRolesValidation();
    },
    onRemoveRole: () => {
      verifyRolesValidation();
      validateRoles();
    },
  });

  const buildTable = () => {
    const ipBankAccountsSelection = getSelectedIpBankAccounts();
    const { rows, timestamp } = addTimestampToIds(ipBankAccountsSelection);

    $invitationRoleTable = buildInvitationTable({
      currentSelection: rows,
      selectOptions,
    });

    $invitationRoleTable.setPageChangeCallback(() => {
      verifyRolesValidation();
      validateRoles();
    });

    setRoleTableRows(timestamp);
    verifyRolesValidation();
  };

  const initTable = () => {
    updateUserName();

    if ($invitationRoleTable) {
      resetRoleTable();
      return;
    }

    buildTable();
  };
  // #endregion Init Roles Table

  // #region Init Element Visibility Listener
  const initElementVisibilityListener = () => {
    const inviteFormPageElement = $inviteFormPage[0];

    if (!inviteFormPageElement) return;

    onChangeVisibility(inviteFormPageElement, (visible) => {
      if (visible) initTable();
    });
  };
  // #endregion Init Element Visibility Listener

  // #region Event Handler Functions
  const onReturnToUserForm = () => {
    $invitationRoleTable = null;

    const $roleBatchCard = $inviteFormPage.find('.JS-batch-profile-card');

    if ($roleBatchCard.length) $roleBatchCard.hide().removeClass('d-flex');

    $('#user_roles').val(JSON.stringify([]));

    setOnlyUniqueIdsForIpBankAccountsTableSelection();

    navigator.navigate(PAGES.preInvitation);
  };

  const onAddNewAccounts = (rows = []) => {
    const { rows: newRows } = addTimestampToIds(rows);

    if (!newRows.length) return;

    resetRoleTable();
    addNewAccountsToTable(newRows);
    verifyRolesValidation();
  };

  const onGoToAddAccounts = () => {
    const $roleBatchCard = $inviteFormPage.find('.JS-batch-profile-card');

    if ($roleBatchCard.length) $roleBatchCard.hide().removeClass('d-flex');

    navigator.navigate(PAGES.addAccount);
  };
  // #endregion Event Handler Functions

  // #region Init Event Handlers
  const initOnClickEventHandlers = () => {
    $inviteFormPage.find('.JS-step2-return').on('click', onReturnToUserForm);
    $inviteFormPage.find('.JS-add-account').on('click', onGoToAddAccounts);
  };

  const initOnChangeEventHandlers = () => {
    $inviteFormPage.on('change', '.JS-invitation-roles-table select', verifyRolesValidation);
  };
  // #endregion Init Event Handlers

  // #region Public Methods
  const METHODS = Object.freeze({
    getValue: onGetRolesValue,
  });
  // #endregion Public Methods

  // #region Init
  const init = () => {
    initElementVisibilityListener();
    initOnClickEventHandlers();
    initOnChangeEventHandlers();

    initAddAccountForm({
      $,
      navigator,
      onAddAccountsCallback: onAddNewAccounts,
    });
  };
  // #endregion Init

  init();

  return METHODS;
};

export default initInvitationPage;
